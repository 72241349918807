import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="log-action"
export default class extends Controller {
  connect() {
    this.boundHandleEvent = this.handleEvent.bind(this); // Bind the event handler to ensure correct context
    document.addEventListener("click", this.boundHandleEvent, true); // Use the capture phase to ensure event tracking before it's stopped elsewhere
    document.addEventListener("scroll", this.boundHandleEvent, true);
    document.addEventListener("keydown", this.boundHandleEvent, true);
  }

  disconnect() {
    document.removeEventListener("click", this.boundHandleEvent, true);
    document.removeEventListener("scroll", this.boundHandleEvent, true);
    document.removeEventListener("keydown", this.boundHandleEvent, true);
  }

  handleEvent(event) {
    const actionType = event.type; // e.g., "click", "scroll", "keydown"

    // Ignore scroll events
    if (actionType === "scroll") {
      return;
    }

    const details = this.getEventDetails(event);

    if (details) {
      this.logAction(actionType, details);
    }
  }


  logAction(actionType, details) {
    fetch("/user_sessions/log_action", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector("meta[name=csrf-token]").content,
      },
      body: JSON.stringify({
        action_type: actionType,
        action_details: details,
      }),
    })
        .then((response) => {
          if (!response.ok) {
            console.error("Failed to log action:", response.statusText);
          }
        })
        .catch((error) => {
          console.error("Error logging action:", error);
        });
  }

  getEventDetails(event) {
    // Customize details based on event type
    switch (event.type) {
      case "click": {
        const target = event.target;
        return {
          element: target.tagName.toLowerCase(),
          id: target.id || null,
          classes: target.className || null,
          text: target.innerText?.trim() || target.value || "N/A",
        };
      }
      case "scroll":
        return {
          scrollTop: Math.round(window.scrollY), // Rounded to avoid excessive precision
          scrollLeft: Math.round(window.scrollX),
        };
      case "keydown":
        return {
          key: event.key,
          code: event.code,
          ctrlKey: event.ctrlKey || undefined,
          shiftKey: event.shiftKey || undefined,
          altKey: event.altKey || undefined,
          metaKey: event.metaKey || undefined,
        };
      default:
        return null; // Default to no additional details
    }
  }
}
