// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"
import './add_jquery'
import * as bootstrap from "bootstrap"
import "./channels"

window.bootstrap = bootstrap;

// Initialize popovers (or tooltips, if used)
let popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
popoverTriggerList.map(function (popoverTriggerEl) {
    return new bootstrap.Popover(popoverTriggerEl);
});

// Initialize Dismissible Alerts on Turbo Frame Loads
document.addEventListener('turbo:load', function () {
    var alertList = [].slice.call(document.querySelectorAll('.alert-dismissible'))
    alertList.map(function (alertEl) {
        return new bootstrap.Alert(alertEl);
    });
});



// Function to display a flash message dynamically
function displayFlash(message, type) {
    const flashContainer = document.getElementById('flash-messages');
    if (flashContainer) {
        const flashMessage = document.createElement('div');
        flashMessage.classList.add('alert', `alert-${type}`, 'alert-dismissible', 'fade', 'show');
        flashMessage.setAttribute('role', 'alert');
        flashMessage.innerHTML = `
            ${message}
            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
        `;

        // Append the new flash message to the container
        flashContainer.appendChild(flashMessage);

        // Automatically dismiss the alert after 5 seconds
        setTimeout(() => {
            flashMessage.classList.remove('show'); // Triggers Bootstrap's fade-out animation
        }, 5000);

        // Remove the alert from the DOM after fade-out transition
        flashMessage.addEventListener('transitionend', (event) => {
            if (event.propertyName === 'opacity' && !flashMessage.classList.contains('show')) {
                flashMessage.remove();
            }
        });
    } else {
        console.warn('Flash message container not found!');
    }
}


// Listen for Turbo Frame Stream Events to Intercept Flash Messages
document.addEventListener("turbo:before-stream-render", function (event) {
    const turboStreamElement = event.target; // The Turbo Stream element

    // Extract the HTML content directly from the Turbo Stream element
    const responseHTML = turboStreamElement.innerHTML;

    if (!responseHTML) {
        console.warn("No HTML content found in Turbo Stream element.");
        return; // Exit if no HTML content to process
    }

    // Look for flash messages
    const flashMessages = responseHTML.match(/<div[^>]*class="alert"[^>]*>([\s\S]*?)<\/div>/g);

    if (flashMessages) {
        flashMessages.forEach((flashHTML) => {
            // Use DOMParser to safely parse HTML
            const parser = new DOMParser();
            const doc = parser.parseFromString(flashHTML, "text/html");
            const flashElement = doc.body.firstChild;

            if (flashElement) {
                const message = flashElement.textContent.trim();
                const type = flashElement.classList.contains("alert-success") ? "success" : "danger";
                displayFlash(message, type);
            }
        });

        // Remove flash messages from Turbo Stream to prevent duplicates
        turboStreamElement.innerHTML = responseHTML.replace(
            /<div[^>]*class="alert"[^>]*>[\s\S]*?<\/div>/g,
            ""
        );
    }
});

document.addEventListener("DOMContentLoaded", () => {
    const alerts = document.querySelectorAll('.alert-dismissible[data-bs-autohide="true"]');
    alerts.forEach(alert => {
        const bsAlert = new bootstrap.Alert(alert);
        setTimeout(() => alert.classList.add('fade-out'), alert.getAttribute('data-bs-delay') || 1000);
        alert.addEventListener('transitionend', () => alert.remove());
    });




});
