import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    connect() {
        // Reapply the flash-background class to ensure the animation is triggered
        this.element.classList.remove("flash-background");
        void this.element.offsetWidth; // Trigger reflow to restart animation
        this.element.classList.add("flash-background");

        // Optional: Remove the flash class after the animation ends
        this.element.addEventListener("animationend", () => {
            this.element.classList.remove("flash-background");
        });
    }
}
